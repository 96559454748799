exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-benefits-index-js": () => import("./../../../src/pages/benefits/index.js" /* webpackChunkName: "component---src-pages-benefits-index-js" */),
  "component---src-pages-benefits-other-awards-js": () => import("./../../../src/pages/benefits/other-awards.js" /* webpackChunkName: "component---src-pages-benefits-other-awards-js" */),
  "component---src-pages-benefits-plan-rules-js": () => import("./../../../src/pages/benefits/plan-rules.js" /* webpackChunkName: "component---src-pages-benefits-plan-rules-js" */),
  "component---src-pages-blank-w-4-forms-js": () => import("./../../../src/pages/blank-w4-forms.js" /* webpackChunkName: "component---src-pages-blank-w-4-forms-js" */),
  "component---src-pages-calculators-by-symmetry-index-js": () => import("./../../../src/pages/calculators-by-symmetry/index.js" /* webpackChunkName: "component---src-pages-calculators-by-symmetry-index-js" */),
  "component---src-pages-calculators-by-symmetry-try-it-free-js": () => import("./../../../src/pages/calculators-by-symmetry/try-it-free.js" /* webpackChunkName: "component---src-pages-calculators-by-symmetry-try-it-free-js" */),
  "component---src-pages-canadian-payroll-forms-js": () => import("./../../../src/pages/canadian-payroll-forms.js" /* webpackChunkName: "component---src-pages-canadian-payroll-forms-js" */),
  "component---src-pages-canadian-tax-engine-js": () => import("./../../../src/pages/canadian-tax-engine.js" /* webpackChunkName: "component---src-pages-canadian-tax-engine-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customer-login-js": () => import("./../../../src/pages/customer-login.js" /* webpackChunkName: "component---src-pages-customer-login-js" */),
  "component---src-pages-customer-stories-index-js": () => import("./../../../src/pages/customer-stories/index.js" /* webpackChunkName: "component---src-pages-customer-stories-index-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-geocoding-example-js": () => import("./../../../src/pages/geocoding/example.js" /* webpackChunkName: "component---src-pages-geocoding-example-js" */),
  "component---src-pages-geocoding-geocoding-and-symmetry-js": () => import("./../../../src/pages/geocoding/geocoding-and-symmetry.js" /* webpackChunkName: "component---src-pages-geocoding-geocoding-and-symmetry-js" */),
  "component---src-pages-geocoding-how-geocoding-works-js": () => import("./../../../src/pages/geocoding/how-geocoding-works.js" /* webpackChunkName: "component---src-pages-geocoding-how-geocoding-works-js" */),
  "component---src-pages-geocoding-index-js": () => import("./../../../src/pages/geocoding/index.js" /* webpackChunkName: "component---src-pages-geocoding-index-js" */),
  "component---src-pages-geocoding-why-is-geocoding-important-js": () => import("./../../../src/pages/geocoding/why-is-geocoding-important.js" /* webpackChunkName: "component---src-pages-geocoding-why-is-geocoding-important-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-local-taxes-index-js": () => import("./../../../src/pages/local-taxes/index.js" /* webpackChunkName: "component---src-pages-local-taxes-index-js" */),
  "component---src-pages-local-taxes-ohio-local-taxes-js": () => import("./../../../src/pages/local-taxes/ohio-local-taxes.js" /* webpackChunkName: "component---src-pages-local-taxes-ohio-local-taxes-js" */),
  "component---src-pages-local-taxes-pennsylvania-local-taxes-js": () => import("./../../../src/pages/local-taxes/pennsylvania-local-taxes.js" /* webpackChunkName: "component---src-pages-local-taxes-pennsylvania-local-taxes-js" */),
  "component---src-pages-minimum-wage-finder-api-demo-js": () => import("./../../../src/pages/minimum-wage-finder/api-demo.js" /* webpackChunkName: "component---src-pages-minimum-wage-finder-api-demo-js" */),
  "component---src-pages-minimum-wage-finder-index-js": () => import("./../../../src/pages/minimum-wage-finder/index.js" /* webpackChunkName: "component---src-pages-minimum-wage-finder-index-js" */),
  "component---src-pages-minimum-wage-finder-portal-demo-js": () => import("./../../../src/pages/minimum-wage-finder/portal-demo.js" /* webpackChunkName: "component---src-pages-minimum-wage-finder-portal-demo-js" */),
  "component---src-pages-multi-state-calculate-withholding-js": () => import("./../../../src/pages/multi-state/calculate-withholding.js" /* webpackChunkName: "component---src-pages-multi-state-calculate-withholding-js" */),
  "component---src-pages-multi-state-examples-js": () => import("./../../../src/pages/multi-state/examples.js" /* webpackChunkName: "component---src-pages-multi-state-examples-js" */),
  "component---src-pages-multi-state-index-js": () => import("./../../../src/pages/multi-state/index.js" /* webpackChunkName: "component---src-pages-multi-state-index-js" */),
  "component---src-pages-multi-state-multi-state-and-symmetry-js": () => import("./../../../src/pages/multi-state/multi-state-and-symmetry.js" /* webpackChunkName: "component---src-pages-multi-state-multi-state-and-symmetry-js" */),
  "component---src-pages-multi-state-nonresident-certificate-js": () => import("./../../../src/pages/multi-state/nonresident-certificate.js" /* webpackChunkName: "component---src-pages-multi-state-nonresident-certificate-js" */),
  "component---src-pages-multi-state-what-is-reciprocity-js": () => import("./../../../src/pages/multi-state/what-is-reciprocity.js" /* webpackChunkName: "component---src-pages-multi-state-what-is-reciprocity-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-payroll-point-api-demo-js": () => import("./../../../src/pages/payroll-point/api-demo.js" /* webpackChunkName: "component---src-pages-payroll-point-api-demo-js" */),
  "component---src-pages-payroll-point-index-js": () => import("./../../../src/pages/payroll-point/index.js" /* webpackChunkName: "component---src-pages-payroll-point-index-js" */),
  "component---src-pages-payroll-point-portal-demo-js": () => import("./../../../src/pages/payroll-point/portal-demo.js" /* webpackChunkName: "component---src-pages-payroll-point-portal-demo-js" */),
  "component---src-pages-payroll-point-try-it-free-js": () => import("./../../../src/pages/payroll-point/try-it-free.js" /* webpackChunkName: "component---src-pages-payroll-point-try-it-free-js" */),
  "component---src-pages-payroll-tax-insights-index-js": () => import("./../../../src/pages/payroll-tax-insights/index.js" /* webpackChunkName: "component---src-pages-payroll-tax-insights-index-js" */),
  "component---src-pages-payroll-tax-insights-press-js": () => import("./../../../src/pages/payroll-tax-insights/press.js" /* webpackChunkName: "component---src-pages-payroll-tax-insights-press-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-psta-js": () => import("./../../../src/pages/psta.js" /* webpackChunkName: "component---src-pages-psta-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-ssta-js": () => import("./../../../src/pages/ssta.js" /* webpackChunkName: "component---src-pages-ssta-js" */),
  "component---src-pages-symmetry-payroll-forms-api-demo-js": () => import("./../../../src/pages/symmetry-payroll-forms/api-demo.js" /* webpackChunkName: "component---src-pages-symmetry-payroll-forms-api-demo-js" */),
  "component---src-pages-symmetry-payroll-forms-index-js": () => import("./../../../src/pages/symmetry-payroll-forms/index.js" /* webpackChunkName: "component---src-pages-symmetry-payroll-forms-index-js" */),
  "component---src-pages-symmetry-payroll-forms-try-it-free-js": () => import("./../../../src/pages/symmetry-payroll-forms/try-it-free.js" /* webpackChunkName: "component---src-pages-symmetry-payroll-forms-try-it-free-js" */),
  "component---src-pages-symmetry-select-js": () => import("./../../../src/pages/symmetry-select.js" /* webpackChunkName: "component---src-pages-symmetry-select-js" */),
  "component---src-pages-symmetry-tax-engine-api-demo-js": () => import("./../../../src/pages/symmetry-tax-engine/api-demo.js" /* webpackChunkName: "component---src-pages-symmetry-tax-engine-api-demo-js" */),
  "component---src-pages-symmetry-tax-engine-index-js": () => import("./../../../src/pages/symmetry-tax-engine/index.js" /* webpackChunkName: "component---src-pages-symmetry-tax-engine-index-js" */),
  "component---src-pages-tax-calendar-download-y-lv-3-f-w-9-j-q-228-js": () => import("./../../../src/pages/tax-calendar/download-yLV3fW9jQ228.js" /* webpackChunkName: "component---src-pages-tax-calendar-download-y-lv-3-f-w-9-j-q-228-js" */),
  "component---src-pages-tax-calendar-index-js": () => import("./../../../src/pages/tax-calendar/index.js" /* webpackChunkName: "component---src-pages-tax-calendar-index-js" */),
  "component---src-pages-tax-compliance-index-js": () => import("./../../../src/pages/tax-compliance/index.js" /* webpackChunkName: "component---src-pages-tax-compliance-index-js" */),
  "component---src-pages-tax-compliance-tax-compliance-content-js": () => import("./../../../src/pages/tax-compliance/tax-compliance-content.js" /* webpackChunkName: "component---src-pages-tax-compliance-tax-compliance-content-js" */),
  "component---src-pages-tax-notification-js": () => import("./../../../src/pages/tax-notification.js" /* webpackChunkName: "component---src-pages-tax-notification-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-webhooks-by-symmetry-index-js": () => import("./../../../src/pages/webhooks-by-symmetry/index.js" /* webpackChunkName: "component---src-pages-webhooks-by-symmetry-index-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-customer-story-js": () => import("./../../../src/templates/customer-story.js" /* webpackChunkName: "component---src-templates-customer-story-js" */)
}

